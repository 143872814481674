import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, SiteDetailsData } from './types';
import { AxiosService, AxiosFileService } from 'api/axiosService';
import { components } from 'openapi-types';
import { PaginatedListState, serializeParamsForQuery } from 'component/hooks/usePaginatedListState';

export const useGetSites = () => {
  return useQuery({
    queryKey: ['get-sites'],
    queryFn: async () => AxiosService.get<components['schemas']['ListSiteResponse']>(`sites`),

    retry: 0,
    gcTime: 0,
  });
};

export const useSearchSites = (search: string) => {
  let url = 'sites';
  if (search) {
    url = `sites?search=${search}&per_page=50`;
  }
  return useQuery({
    queryKey: [`search-sites-${search}`],
    queryFn: async () => AxiosService.get<components['schemas']['ListSiteResponse']>(url),
    retry: 0,
    gcTime: 0,
  });
};

export interface SiteDetailDomain {
  maindomain?: components['schemas']['MainDomain'];
}

export interface AdditionalDomain {
  dns_provider: string;
  domain: string;
  hostname_status?: string;
  id: number;
  ssl_status?: string;
  validation_method?: string;
  validation_records?: Array<{
    type: string;
    name: string;
    value: string;
    status: string;
  }>;
}

export interface AdditionalDomains {
  additional_domains: Array<AdditionalDomain> | null;
}

interface SSOResponse {
  sign_on_url: string;
}

interface ClearSpecificPathData {
  files: string[];
}

type SiteDomains = {
  maindomain: components['schemas']['MainDomain'];
  additional_domains: components['schemas']['MainDomain'][];
}[];
export type SiteDetail = NonNullable<components['schemas']['GetSiteResponse']['result']>;

export type SiteUser = NonNullable<NonNullable<SiteDetail>['site_users']>[number];

export const useSiteDetails = (data: SiteDetailsData, options?: { enabled: boolean }) => {
  return useQuery({
    queryKey: [`siteDetail${data.id}`],
    queryFn: async () =>
      // TODO - match from the top
      await AxiosService.get<components['schemas']['GetSiteResponse']>(`sites/${data.id}`),
    ...options,
    retry: 0,
    staleTime: 5000,
    gcTime: 10,
  });
};

export interface SitePaginationState {
  perPage: number;
  activePageNumber: number;
  filter: string;
  sortAttr: 'created_at' | 'domain' | 'label' | 'disk_usage';
  sortOrder: 'asc' | 'desc';
}

export type SiteListItem = NonNullable<components['schemas']['ListSiteResponse']['result']>[number];

export const useSites = (
  { perPage, activePageNumber, filter, sortAttr, sortOrder }: SitePaginationState,
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: ['sites'],

    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListSiteResponse']>(
        `sites?page=${activePageNumber}&per_page=${perPage}&search=${filter}&sort=${sortAttr}&direction=${sortOrder}`
      ),

    retry: 0,
    gcTime: 0,
    ...options,
  });
};

export const useUserSites = (
  { perPage, activePageNumber, filter, sortAttr, sortOrder }: SitePaginationState,
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: ['sites-for-user'],

    queryFn: async () => {
      return await AxiosService.get<components['schemas']['ListSiteResponse']>(
        `sites/users?page=${activePageNumber}&per_page=${perPage}&search=${filter}&sort=${sortAttr}&direction=${sortOrder}`
      );
    },

    retry: 0,
    staleTime: 5000,
    gcTime: 10,
    ...options,
  });
};

export const useSitesNew = (state: PaginatedListState, options?: { enabled: boolean }) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['sites', serializedParams],

    queryFn: async () => {
      return await AxiosService.get<components['schemas']['ListSiteResponse']>(
        `sites?${serializedParams}`
      );
    },

    retry: 0,
    staleTime: 5000,
    gcTime: 10,
    ...options,
  });
};

export const useSitesUsersNew = (state: PaginatedListState, options: { enabled: boolean }) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['sites', serializedParams],

    queryFn: async () => {
      return await AxiosService.get<components['schemas']['ListSiteResponse']>(
        `sites/users?${serializedParams}`
      );
    },

    retry: 0,
    staleTime: 5000,
    gcTime: 10,
    ...options,
  });
};

export interface SetSiteLabelData {
  label: string;
}

export const useSetSiteLabel = (siteId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`setSiteLabel`],

    mutationFn: async (data: SetSiteLabelData) =>
      await AxiosService.patch<ApiResponse<SiteDetail>>(`sites/${siteId}`, data),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sites'],
      });
      queryClient.invalidateQueries({
        queryKey: [`siteDetail${siteId}`],
      });
    },
  });
};

export const useDeleteSiteUser = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete-site-user'],

    mutationFn: async (userId: number) =>
      await AxiosService.delete<{ success: boolean }>(`sites/${siteId}/users/${userId}`, {}),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`siteDetail${siteId}`],
      });
    },
  });
};

export const useDeleteSite = (siteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['sites'],
    mutationFn: async () => await AxiosService.delete(`sites/${siteId}`),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['sites'],
      }),
  });
};

export const useWpAdminSso = (siteId: string) => {
  return useQuery({
    queryKey: ['wpadmin-sso'],

    queryFn: async () => AxiosService.get<ApiResponse<SSOResponse>>(`sites/${siteId}/wp/login`),
  });
};

export interface SuccessStatus {
  status: 'success';
}

export const usePurgeAllCache = (siteId: string) => {
  return useMutation({
    mutationKey: ['site-cache'],

    mutationFn: async () =>
      await AxiosService.post<ApiResponse<SuccessStatus>>(`sites/${siteId}/cache/purge_everything`),
  });
};

export const usePurgeSpecificPaths = (siteId: string) => {
  return useMutation({
    mutationKey: ['site-cache'],

    mutationFn: async (data: ClearSpecificPathData) =>
      await AxiosService.post(`sites/${siteId}/cache/purge`, data),
  });
};

export const useCreateStaging = (siteId: string) => {
  return useMutation({
    mutationKey: ['create-site-staging'],
    mutationFn: async () =>
      await AxiosService.post<components['schemas']['TaskIDResponse']>(`sites/${siteId}/staging`),
  });
};

/**
 * this hook is a little weird. we can to be able to refetch until we get the desired status but only after an action like delete staging or create staging
 * @param siteId
 * @returns
 */
export const useGetTaskStatus = (siteId: string) => {
  return useMutation({
    mutationKey: [`getTaskStatus-${siteId}`],

    mutationFn: async (taskId: string) =>
      await AxiosService.get<components['schemas']['ListTasksResult']>(
        `sites/${siteId}/tasks?task_id=${taskId}`
      ),
  });
};

export const useDeleteStaging = (siteId: string) => {
  return useMutation({
    mutationKey: [`delete-site-staging-${siteId}`],
    mutationFn: async () =>
      await AxiosService.delete<components['schemas']['TaskIDResponse']>(`sites/${siteId}/staging`),
  });
};

export const usePublishStaging = (siteId: string) => {
  return useMutation({
    mutationKey: [`publish-site-staging-${siteId}`],

    mutationFn: async () =>
      await AxiosService.post<components['schemas']['TaskIDResponse']>(
        `sites/${siteId}/staging/publish`
      ),
  });
};

export interface SiteSettings {
  current_php_version: string;
  wp_core_auto_updates: 0 | 1 | 2;
  wp_theme_auto_updates: 0 | 1;
  wp_plugin_auto_updates: 0 | 1;
  ssh_access: 0 | 1;
  wp_activity_log: 0 | 1;
}

export const useGetSiteSettings = (siteId: string) => {
  return useQuery({
    queryKey: [`site-settings-${siteId}`],
    queryFn: async () =>
      await AxiosService.get<ApiResponse<SiteSettings>>(`sites/${siteId}/settings`),
  });
};

export const useUpdateSettings = (siteId: string) => {
  return useMutation({
    mutationKey: [`site-settings-${siteId}`],

    mutationFn: async (body: Partial<SiteSettings>) =>
      await AxiosService.patch(`sites/${siteId}/settings`, body),
  });
};

interface PhpMyAdminSSOResponse {
  phpmyadmin_sign_on_url: string;
}
export const useGetPhpMyAdminSso = (siteId: string) => {
  return useQuery({
    queryKey: [`phpmyadmin-sso-${siteId}`],

    queryFn: async () =>
      AxiosService.get<ApiResponse<PhpMyAdminSSOResponse>>(`sites/${siteId}/pma/login`),
  });
};

export interface ViewSiteFileResponse {
  content: string;
}

export const useViewSiteFile = (siteId: string, filename?: string, directory?: string) => {
  return useQuery({
    queryKey: [`site-file-${siteId}`],

    queryFn: async () => {
      let url = `sites/${siteId}/files/view?filename=${filename ?? ''}`;

      if (directory) {
        url += `&directory=${directory ?? ''}`;
      }

      return AxiosService.get<ApiResponse<ViewSiteFileResponse>>(url);
    },

    enabled: Boolean(filename),
    retry: 0,
    gcTime: 0,
  });
};

export type SiteFile = NonNullable<components['schemas']['File']['files']>[number];
export type SiteFilesResponse = components['schemas']['ListFilesResponse']['result'];

export const useSiteFiles = (siteId: string, path?: string) => {
  return useQuery({
    queryKey: [`site-files-${siteId}`],

    queryFn: async () =>
      AxiosService.get<components['schemas']['ListFilesResponse']>(
        `sites/${siteId}/files${path ? `?path=${path}` : ''}`
      ),

    retry: 0,
    gcTime: 0,
  });
};

export type FileManagerFile = components['schemas']['FileManagerFile'];

export const useSiteFileManager = (siteId: string, state: PaginatedListState) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['site-files', siteId, serializedParams],

    queryFn: async () =>
      AxiosService.get<components['schemas']['ListFileManagerFilesResponse']>(
        `sites/${siteId}/file_manager/files?${serializedParams}`
      ),

    retry: 0,
    gcTime: 0,
  });
};

export type CreateFolderRequest = components['schemas']['CreateFolderRequest'];

export const useCreateSiteFolder = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`create-site-folder-${siteId}`],

    mutationFn: async (data: CreateFolderRequest) =>
      await AxiosService.post<components['schemas']['PathResponse']>(
        `sites/${siteId}/files/folder`,
        data
      ),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [`site-files-${siteId}`],
      }),
  });
};

export type UploadFileRequest = components['schemas']['UploadFileRequest'];

export const useUploadSiteFile = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`upload-site-folder-${siteId}`],

    mutationFn: async (data: UploadFileRequest) =>
      await AxiosService.post<components['schemas']['PathResponse']>(`sites/${siteId}/files`, data),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [`site-files-${siteId}`],
      }),
  });
};

type PutSiteFileData = {
  file: string;
  path: string;
  content: string;
};

type PutSiteFileResponse = {
  success: boolean;
  path: string;
};

export const usePutSiteFile = (siteId: string) => {
  return useMutation({
    mutationKey: [`put-site-file-${siteId}`],

    mutationFn: async (data: PutSiteFileData) =>
      await AxiosService.put<ApiResponse<PutSiteFileResponse>>(`sites/${siteId}/files`, data),
  });
};

type DeleteSiteFileData = {
  file: string;
};

export const useDeleteSiteFile = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`delete-site-file-${siteId}`],

    mutationFn: async (data: DeleteSiteFileData) =>
      await AxiosService.delete<ApiResponse<PutSiteFileResponse>>(`sites/${siteId}/files`, {
        data,
      }),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [`site-files-${siteId}`],
      }),
  });
};

export function useDownloadSiteFile(siteId: string, filename?: string, directory?: string) {
  let url = `sites/${siteId}/files/download?filename=${filename ?? ''}`;

  if (directory) {
    url += `&directory=${directory ?? ''}`;
  }
  return () =>
    AxiosFileService.get<any>(url, {
      responseType: 'blob', // important
    });
}

export const useCreateSite = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`create-site-managed`],

    mutationFn: async (data: components['schemas']['CreateSiteRequest']) =>
      await AxiosService.post<components['schemas']['CreateSiteResponse']>(`sites`, {
        ...data,
      }),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [`sites`],
      }),
  });
};

type CreatePartnerSiteRequest = components['schemas']['CreateSiteRequest'] & { domain: string };

export const useCreatePartnerSite = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`create-site-partner`],

    mutationFn: async (data: CreatePartnerSiteRequest) =>
      await AxiosService.post<components['schemas']['CreateSiteResponse']>(`partner/sites`, {
        ...data,
      }),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [`sites`],
      }),
  });
};

interface ChangeSiteDomainData {
  domain: string;
}

interface ChangeSiteDomainResponse {
  dns_provider: string;
  validation_records: Array<{
    type: string;
    name: string;
    value: string;
    status: string;
  }>;
}

export const useChangeSiteDomain = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`change-domain-for-${siteId}`],

    mutationFn: async (data: ChangeSiteDomainData) =>
      await AxiosService.put<ApiResponse<ChangeSiteDomainResponse>>(`sites/${siteId}/maindomain`, {
        ...data,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`siteDetail${siteId}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`sites`],
      });
    },
  });
};

export const useSiteStartEventsLogging = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`change-domain-for-${siteId}`],

    mutationFn: async () =>
      await AxiosService.post<ApiResponse<ChangeSiteDomainResponse>>(
        `sites/${siteId}/activity/enable`,
        {}
      ),

    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [`site-settings-${siteId}`],
      });

      // Snapshot the previous value
      const previousSiteSettings = queryClient.getQueryData<{ data: ApiResponse<SiteSettings> }>([
        `site-settings-${siteId}`,
      ]);

      if (previousSiteSettings) {
        // Optimistically update to the new value
        const newSiteSettings = { ...previousSiteSettings };
        newSiteSettings.data.result.wp_activity_log = 1;

        queryClient.setQueryData([`site-settings-${siteId}`], newSiteSettings);
      }

      return { previousSiteSettings };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([`site-settings-${siteId}`], context?.previousSiteSettings);
    },

    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [`site-settings-${siteId}`],
      });
    },
  });
};

export const useSiteStopEventsLogging = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`change-domain-for-${siteId}`],

    mutationFn: async () =>
      await AxiosService.post<ApiResponse<ChangeSiteDomainResponse>>(
        `sites/${siteId}/activity/disable`,
        {}
      ),

    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [`site-settings-${siteId}`],
      });

      // Snapshot the previous value
      const previousSiteSettings = queryClient.getQueryData<{ data: ApiResponse<SiteSettings> }>([
        `site-settings-${siteId}`,
      ]);

      if (previousSiteSettings) {
        // Optimistically update to the new value
        const newSiteSettings = { ...previousSiteSettings };
        newSiteSettings.data.result.wp_activity_log = 0;

        queryClient.setQueryData([`site-settings-${siteId}`], newSiteSettings);
      }

      return { previousSiteSettings };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([`site-settings-${siteId}`], context?.previousSiteSettings);
    },

    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [`site-settings-${siteId}`],
      });
    },
  });
};

export const useSiteSetActivityLoggingState = (siteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (enable: boolean) =>
      await AxiosService.post<ApiResponse<ChangeSiteDomainResponse>>(
        `sites/${siteId}/activity/${enable ? 'enable' : 'disable'}`,
        {}
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`site-settings-${siteId}`],
      });
    },
  });
};

export const useDeleteSiteTemplate = (templateId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`delete-site-template-${templateId}`],
    mutationFn: async () =>
      await AxiosService.delete<components['schemas']['SiteTemplateResponse']>(
        `sites/templates/${templateId}`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['site-templates'] });
    },
  });
};

export const useSiteTemplates = () => {
  return useQuery({
    queryKey: ['site-templates'],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListSiteTemplatesResponse']>(`sites/templates`),
    retry: 0,
    staleTime: 5000,
    gcTime: 10,
  });
};

export const useSiteTemplate = (templateId: string) => {
  return useQuery({
    queryKey: [`site-templates-${templateId}`],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['SiteTemplateResponse']>(
        `sites/templates/${templateId}`
      ),
    retry: 0,
    staleTime: 5000,
    gcTime: 10,
  });
};

export const useCreateSiteTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`create-site-template`],
    mutationFn: async (data: components['schemas']['CreateSiteTemplateRequest']) =>
      await AxiosService.post<components['schemas']['SiteTemplateResponse']>(
        `sites/templates`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['site-templates'] });
    },
  });
};

export const useSiteUsers = (siteId: string | number, state: PaginatedListState) => {
  const serializedParams = serializeParamsForQuery(state);

  return useQuery({
    queryKey: ['sites/{id}/users', siteId, serializedParams],

    queryFn: async () => {
      return await AxiosService.get<components['schemas']['ListSiteSiteUsersResponse']>(
        `sites/${siteId}/users?${serializedParams}`
      );
    },

    retry: 0,
    staleTime: 5000,
    gcTime: 10,
  });
};
